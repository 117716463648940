import scrollToElement from "scroll-to-element";

// https://chaseohlson.com/gatsby-link-anchor-navigation
const handleLinkClick = (link) => {
  if (typeof window !== "undefined" && link.includes("#")) {
    const [anchorPath, anchor] = link.split("#");
    if (window.location.pathname === anchorPath) {
      window.location = link;
      scroller(`#${anchor}`, -80);
    }
  }
};

function scroller(target, offset) {
  scrollToElement(target, {
    offset,
  });
}

export default handleLinkClick;
