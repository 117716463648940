import {
  ALL_ROADMAPS,
  CURRENT_ACTIVE_ROADMAP
} from "../roadmap/RoadmapVersions";

export default function getPrevNextRoadmaps(roadmapId) {
  const roadmapIndex = ALL_ROADMAPS.findIndex(
    (roadmap) => roadmap === roadmapId
  );

  const prev = roadmapIndex === 0 ? null : ALL_ROADMAPS[roadmapIndex - 1];
  const next =
    roadmapIndex === ALL_ROADMAPS.length - 1
      ? null
      : ALL_ROADMAPS[roadmapIndex + 1];

  return {
    prev: {
      id: prev,
      name: prev && prev.replace(/-/g, " ")
    },
    next: {
      id: next,
      name: next && next.replace(/-/g, " ")
    }
  };
}
